import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import NumberTextField from "../../../components/Form/NumberTextField/NumberTextField";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import DateTimePicker from "../../../components/Form/DateTimePicker/DateTimePicker";
import Select from "../../../components/Form/Select/Select";
import {Fuel, OdometerReading, OdometerReadingRequest} from "../../../API/bus/types";
import Button from "../../../components/Button/Button";
import {getFuelTranslationFromStr} from "../../../utils/enumTranslations";
import * as Yup from "yup";
import {validationSchema} from "../../../utils/formValidation";
import {isEqual} from "lodash";
import {Clear} from "@mui/icons-material";
import {getDate, getDateTimeString} from "../../../utils/dateUtils";
import {decimalToDisplayStr, strToDecimal} from "../../../utils/utils";
import {getEnumFromStr} from "../../../utils/formUtils";

export interface ReadingFormData {
    dateTime: Date;
    reading: string;
    fuelType: string | null;
    fuelAmount: string;
}

export const odometerReadingValidationSchema = Yup.object().shape({
    dateTime: Yup.date().required('Aeg on kohustulik väli'),
    reading: validationSchema('Näit').fields.numberRequired,
});

export const refuellingValidationSchema = Yup.object().shape({
    dateTime: Yup.date().required('Aeg on kohustulik väli'),
    reading: validationSchema('Näit').fields.numberRequired,
    fuelType: validationSchema('Kütus').fields.textFieldRequired,
    fuelAmount: validationSchema('Kogus').fields.numberTextFieldRequired,
});

const getInitialValues = (
    bus: {fuelTypes: Fuel[]},
    copiedData?: OdometerReading,
    includeRefuelling?: boolean,
    suggestedDateTime?: string,
    suggestedReading?: number
) => copiedData ? {
    dateTime: getDate(copiedData.dateTime),
    reading: copiedData.reading.toString(),
    fuelType: copiedData.fuelType?.toString() ?? null,
    fuelAmount: decimalToDisplayStr(copiedData.fuelAmount),
} : {
    dateTime: suggestedDateTime ? getDate(suggestedDateTime) : new Date(),
    reading: suggestedReading?.toString() ?? '',
    fuelType: includeRefuelling ? bus.fuelTypes[0] ?? null : null,
    fuelAmount: '',
};

export const getOdometerReadingRequest = (form: ReadingFormData): OdometerReadingRequest => ({
    dateTime: getDateTimeString(form.dateTime),
    reading: Number(form.reading),
    fuelType: form.fuelType ? getEnumFromStr(form.fuelType, Fuel) : undefined,
    fuelAmount: form.fuelAmount && form.fuelAmount !== '' ? strToDecimal(form.fuelAmount) : undefined,
});

export interface OdometerAndRefuellingData {
    includeRefuelling: boolean;
    copiedData?: OdometerReading;
    suggestedDateTime?: string;
    suggestedReading?: number;
}

export interface ReadingFormProps extends OdometerAndRefuellingData {
    bus: {fuelTypes: Fuel[]};
    handleSubmit: (formData: ReadingFormData, helpers: FormikHelpers<ReadingFormData>) => void;
    handleCloseDialog?: () => void;
}

const ReadingForm = ({
    bus,
    includeRefuelling,
    handleSubmit,
    copiedData,
    suggestedDateTime,
    suggestedReading,
    handleCloseDialog,
}: ReadingFormProps) => {
    const [initialValues, setInitialValues] = useState<ReadingFormData>(
        getInitialValues(bus, copiedData, includeRefuelling, suggestedDateTime, suggestedReading)
    );

    useEffect(() => {
        setInitialValues(getInitialValues(bus, copiedData, includeRefuelling, suggestedDateTime, suggestedReading));
    }, [includeRefuelling, copiedData]);

    const areInitialValuesEqualToValues = (formikProps: FormikProps<ReadingFormData>): boolean => {
        return isEqual(formikProps.initialValues, formikProps.values);
    };

    const options = bus.fuelTypes;

    return (
        <Box sx={{my: 1}}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={includeRefuelling ? refuellingValidationSchema : odometerReadingValidationSchema}
            >
                {(formikProps: FormikProps<ReadingFormData>) =>
                    <Form>
                        <Box sx={{display: 'flex'}}>
                            <Box sx={{mr: 0.5, width: '60%'}}>
                                <DateTimePicker name="dateTime" label="Aeg*" fullWidth />
                            </Box>
                            <Box sx={{width: '40%'}}>
                                <NumberTextField name="reading" label="Odomeetri näit*" />
                            </Box>
                        </Box>
                        {includeRefuelling && <Box sx={{display: 'flex'}}>
                            <Box sx={{mr: 0.5, width: '60%'}}>
                                <Select name="fuelType"
                                        label="Kütuseliik*"
                                        options={options}
                                        sx={{width: '100%'}}
                                        translationFunction={getFuelTranslationFromStr}
                                        translationEnumType={Fuel}
                                />
                            </Box>
                            <Box sx={{width: '40%'}}>
                                <NumberTextField name="fuelAmount" label="Kütuse kogus*" decimals={2} />
                            </Box>
                        </Box>}
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: handleCloseDialog ? 'center' : 'flex-start',
                            pt: handleCloseDialog ? 2 : 0
                        }}>
                            {handleCloseDialog &&
                                <Box sx={{width: {xs: '50%', sm: 'fit-content'}}} pr={1}>
                                    <Button
                                        disabled={formikProps.isSubmitting}
                                        variant="outlined"
                                        text="Loobu"
                                        onClick={handleCloseDialog}
                                        startIcon={<Clear />}
                                    />
                                </Box>
                            }
                            <Box sx={{width: {xs: '50%', sm: 'fit-content'}}}>
                                <Button
                                    text="Salvesta"
                                    type="submit"
                                    disabled={(areInitialValuesEqualToValues(formikProps) && !suggestedReading) || formikProps.isSubmitting}
                                />
                            </Box>
                        </Box>
                    </Form>
                }
            </Formik>
        </Box>
    );
};

export default ReadingForm;