import {RootState} from "../../../../store";
import {createSelector, EntityId} from "@reduxjs/toolkit";
import dayjs, {Dayjs} from "dayjs";
import {
    calculateWorkScheduleItemHours,
    CalculatorDriverContract,
    CalculatorScheduledItem,
    contractIsActiveOnMonth,
    formatName,
    getNumberOfDaysWorked,
    getNumberOfReserveDays,
    getStatisticsCalculators,
    isActivityType,
    isOnPublicHoliday,
    isRequestType,
    isReserveItem
} from "../utils";
import {Contract, ResourceType, WorkScheduleItemType} from "../../../../API/types";
import {
    selectAllWorkScheduleItems,
    selectWorkScheduleItemEntityId,
    selectWorkScheduleItemWithWorkItems,
    UnscheduledWorkGroup,
    WorkItem,
    WorkScheduleItem,
    WorkScheduleItemWithHours
} from "../../../../store/workScheduleItemSlice";
import {selectWorkGroupWithItems, WorkGroupWithItems} from "../../../../store/workGroupSlice";
import {selectAllDriverContracts, selectDriverContractById} from "../../../../store/driverContractSlice";
import {selectSelectedRegion} from "../../../../store/regionSlice";
import {selectAllCalendarEntries} from "../../../../store/calendarEntriesSlice";
import {selectAllBuses, selectBusById, selectBusIds} from "../../../../store/busSlice";
import {selectAllDrivers, selectDriverById} from "../../../../store/driverSlice";
import {WorkScheduleRowStats} from "../types";
import {selectToggledResourceType} from "../../../../store/viewSlice";
import {Cell, Row} from "write-excel-file";
import {isOnWeekend} from "../../../../utils/utils";
import {getCellLabel} from "../planningView/components/WorkScheduleTable/components/ItemCell";
import {Weekday} from "../../../../utils/dateUtils";

const selectScheduledItems = createSelector(
    selectAllWorkScheduleItems,
    (items) => items.filter(item => !!item.resourceId)
        .map(item => ({
            ...item,
            confirmedAt: item.confirmedAt ?? null,
        }))
);
const selectResourceId = (_: RootState, resourceId: EntityId) => resourceId;
const selectScheduleType = (state: RootState) => state.workSchedule.view.scheduleType;
export const selectScheduledItemsByResourceId = createSelector(
    [selectScheduledItems, selectResourceId, selectScheduleType],
    (items, resourceId, scheduleType): WorkScheduleItem[] => items
        .filter(item => scheduleType === 'KINNITATUD' ? item.confirmedAt !== null : item.confirmedAt === null)
        .filter(item => item.resourceId === parseInt(resourceId.toString())),
);
export const selectMonth = (state: RootState) => state.workSchedule.view.month;
export const selectPrevWeekDays = createSelector(
    selectMonth,
    (month) => {
        const date = dayjs(month);

        return [...Array(7)].map((_, i) => date.subtract(7 - i, 'days'));
    }
);
export const selectCurrentMonthDays = createSelector(
    selectMonth,
    (month) => {
        const date = dayjs(month);
        return [...Array(date.daysInMonth())].map((_, i) => date.add(i, 'days'));
    }
);

export const selectConfirmedAt = createSelector(
    selectAllWorkScheduleItems,
    selectMonth,
    (items, month) => items
        .find(item => item.confirmedAt && item.endDate > month)
        ?.confirmedAt
);

const selectItemsByDateAndId = (id: EntityId, date: Dayjs) => createSelector(
    (state: RootState) => selectScheduledItemsByResourceId(state, id),
    selectWorkScheduleType,
    (items, scheduleType) => {
        const confirmed = scheduleType === 'KINNITATUD';
        return items.filter(item => item.startDate <= date.format('YYYY-MM-DD') && item.endDate >= date.format('YYYY-MM-DD'))
            .filter(item => {
                if (confirmed && item.confirmedAt === null) {
                    return false;
                }
                return !(!confirmed && item.confirmedAt !== null);
            });
    }
);

export const selectActivityByDayAndId = (id: EntityId, day: Dayjs) => (state: RootState) => {
    const items = selectItemsByDateAndId(id, day)(state);
    const activity = items.find(item => isActivityType(item.type));
    if (!activity) {
        return activity;
    }
    const activityId = selectWorkScheduleItemEntityId(activity);

    return selectWorkScheduleItemWithCalculatedHours(state, activityId);
};

export const selectRequestByDayAndId = (id: EntityId, day: Dayjs) => createSelector(
    selectItemsByDateAndId(id, day),
    (items) => items.find(item => isRequestType(item.type)),
);

export const selectIsRowActive = (state: RootState, id: EntityId): boolean => {
    const type = selectToggledResourceType(state);

    if (type === ResourceType.DRIVER) {
        const driverContract = selectDriverContractById(state, id);

        return driverContract?.active ?? false;
    } else {
        const bus = selectBusById(state, id);

        return bus?.active ?? false;
    }
};

export const selectWorkScheduleType = (state: RootState) => state.workSchedule.view.scheduleType;

const selectWorkScheduleItemsByScheduleType = createSelector(
    selectAllWorkScheduleItems,
    selectWorkScheduleType,
    (items, type) => {
        return type === 'KINNITATUD'
            ? items.filter(item => item.confirmedAt)
            : items.filter(item => !item.confirmedAt);
    }
);

export const selectUnscheduledWorkGroups = createSelector(
    (state: RootState): WorkScheduleItemWithHours[] => {
        const workScheduleItems = selectWorkScheduleItemsByScheduleType(state);
        const unscheduled = workScheduleItems
            .filter(item => !item.resourceId && item.type === WorkScheduleItemType.WORK_GROUP);

        const result: WorkScheduleItemWithHours[] = [];
        unscheduled.forEach(item => {
            const itemWithCalculatedHours = selectWorkScheduleItemWithCalculatedHours(
                state,
                selectWorkScheduleItemEntityId(item)
            );
            if (itemWithCalculatedHours && itemWithCalculatedHours.workGroupId) {
                result.push(itemWithCalculatedHours);
            }
        });

        return result;
    },
    (unscheduled): UnscheduledWorkGroup[] => {
        return unscheduled.map(itemWithCalculatedHours => ({
            workScheduleItemId: itemWithCalculatedHours.id,
            id: itemWithCalculatedHours.workGroupId ?? 0,
            code: itemWithCalculatedHours.workGroupCode,
            date: itemWithCalculatedHours.startDate,
            regionId: itemWithCalculatedHours.regionId,
            workScheduleItemStartDateTime: itemWithCalculatedHours.startDateTime,
            workScheduleItemEndDateTime: itemWithCalculatedHours.endDateTime,
            workGroupStartDateTime: itemWithCalculatedHours.workGroupStartDateTime,
            workGroupEndDateTime: itemWithCalculatedHours.workGroupEndDateTime,
            hours: itemWithCalculatedHours.hours,
        }))
    }
);

export const selectUnscheduledWorkGroupsOnDay = createSelector(
    selectUnscheduledWorkGroups,
    (_: RootState, day: string) => day,
    (unscheduled, day): UnscheduledWorkGroup[] => unscheduled
        .filter(item => item.date === day)
        .sort((a,b) => a.code.localeCompare(b.code)),
);

export const selectNumberOfUnscheduledWorkGroupsOnDay = ((state: RootState, day: string) => {
    return selectAllWorkScheduleItems(state)
        .filter((wsi) => wsi.resourceId === undefined && wsi.startDate === day)
        .length
});

export const selectUniqueUnscheduledWorkGroups = createSelector(
    selectUnscheduledWorkGroups,
    (unscheduled) => {
        const groupedItems: UnscheduledWorkGroup[] = [];

        unscheduled.forEach(item => {
            if (!groupedItems.find(groupedItem =>
                groupedItem.code === item.code
                && groupedItem.workGroupStartDateTime === item.workGroupStartDateTime
                && groupedItem.workGroupEndDateTime === item.workGroupEndDateTime
            )) {
                groupedItems.push(item);
            }
        });

        return groupedItems.sort((a, b) => a.code.localeCompare(b.code));
    },
);

export const selectContractStats = (state: RootState, contractId: EntityId): WorkScheduleRowStats => {
    const contract = selectDriverContractById(state, contractId);
    if (!contract) {
        return {
            averageDailyHours: 0,
            contractType: Contract.EMPLOYMENT_CONTRACT,
            difference: 0,
            nominalHours: 0,
            plannedHours: 0,
            reserveDays: 0,
            weeklyHours: 0,
            workedDays: 0,
            workedWeekendDays: 0,
            nonWorkToWorkRatio: 0,
        };
    }
    const month = selectMonth(state);
    const startOfMonth = dayjs(month).startOf('month');
    const calendarEntries = selectAllCalendarEntries(state);
    const items = selectScheduledItemsByResourceId(state, contractId);
    const itemsWithHours: WorkScheduleItemWithHours[] = [];
    items.forEach(item => {
        if (dayjs(item.endDate).isBefore(startOfMonth)) {
            return;
        }
        const itemWithHours = selectWorkScheduleItemWithCalculatedHours(
            state,
            selectWorkScheduleItemEntityId(item)
        );
        if (itemWithHours) {
            itemsWithHours.push(itemWithHours);
        }
    });
    const statsCalculator = getStatisticsCalculators(dayjs(month), calendarEntries);
    const calculatorItems: CalculatorScheduledItem[] = itemsWithHours.map(item => ({
        ...item,
        startTime: item.startDateTime,
        endTime: item.endDateTime,
        startDate: dayjs(item.startDate),
        endDate: dayjs(item.endDate),

    }));
    const calculatorContract: CalculatorDriverContract = {
        contractStartDate: dayjs(contract?.startDate),
        contractEndDate: contract?.endDate ? dayjs(contract.endDate) : null,
        nominalWeeklyWorkingHours: contract?.nominalWeeklyWorkingHours ?? 0,
    };

    const totalHours = statsCalculator.getTotalHours(calculatorItems);
    const nominalHours = statsCalculator.getNominalHours(calculatorContract, calculatorItems);

    return {
        averageDailyHours: statsCalculator.getAverageDayHours(calculatorItems),
        contractType: contract?.type ?? Contract.EMPLOYMENT_CONTRACT,
        difference: totalHours - nominalHours,
        nominalHours: statsCalculator.getNominalHours(calculatorContract, calculatorItems),
        plannedHours: totalHours,
        reserveDays: getNumberOfReserveDays(calculatorItems),
        weeklyHours: contract?.nominalWeeklyWorkingHours,
        workedDays: getNumberOfDaysWorked(calculatorItems),
        workedWeekendDays: statsCalculator.getWorkingDaysOnWeekend(calculatorItems),
        nonWorkToWorkRatio: nominalHours !== 0 ? statsCalculator.getNonWorkHours(calculatorItems) / nominalHours : 0,
    }
};

export const selectDriverSortingPositions = (state: RootState) => state.workSchedule.view.driverSortingPositions;

export const selectActiveDriverIdsOnMonth = createSelector(
    selectAllDrivers,
    selectMonth,
    selectSelectedRegion,
    (drivers, month, selectedRegion) => {
        return drivers
            .filter(driver => driver.active && driver.regionIds.includes(selectedRegion?.id ?? 0))
            .filter(driver => driver.contracts.some(contract => contractIsActiveOnMonth(contract, month)))
            .map(driver => driver.id);
    }
);

export const selectActiveBusIds = createSelector(
    selectBusIds,
    selectAllBuses,
    selectAllWorkScheduleItems,
    selectSelectedRegion,
    (busIds, buses, items, selectedRegion) => {
        const busIdsInScheduledItems = items
            .filter(item => item.resourceId)
            .map(item => item.resourceId);
        const activeBusIdsInRegion = buses
            .filter(bus => bus.active && bus.regionIds.includes(selectedRegion?.id ?? 0))
            .map(bus => bus.id);

        return busIds.filter(id =>
            activeBusIdsInRegion.includes(parseInt(id.toString()))
            || busIdsInScheduledItems.includes(parseInt(id.toString()))
        );
    }
);

export interface WorkScheduleItemWithWorkGroup extends WorkScheduleItem {
    workGroup?: WorkGroupWithItems;
    items?: WorkItem[];
}

export const selectWorkScheduleItemWithWorkGroup = (state: RootState, id: EntityId): WorkScheduleItemWithWorkGroup | undefined => {
    const workScheduleItem = selectWorkScheduleItemWithWorkItems(state, id);
    if (!workScheduleItem) {
        return undefined;
    }
    if (!workScheduleItem.workGroupId) {
        return {
            ...workScheduleItem,
            workGroup: undefined,
        };
    }
    const workGroup = selectWorkGroupWithItems(state, workScheduleItem.workGroupId, workScheduleItem.startDate);

    if (!workGroup) {
        return {
            ...workScheduleItem,
            workGroup: undefined,
            items: workScheduleItem.items,
        }
    }

    return {
        ...workScheduleItem,
        workGroup: {
            ...workGroup,
            items: workGroup.items
        },
    }
};

export const selectWorkScheduleItemWithCalculatedHours = (state: RootState, id: EntityId) => {
    const workScheduleItem = selectWorkScheduleItemWithWorkGroup(state, id);
    if(!workScheduleItem) {
        return undefined;
    }

    return {
        id: workScheduleItem.id,
        type: workScheduleItem.type,
        resourceId: workScheduleItem.resourceId,
        resourceType: workScheduleItem.resourceType,
        status: workScheduleItem.status,
        workGroupId: workScheduleItem.workGroupId,
        workGroupCode: workScheduleItem.workGroup?.code ?? '',
        startDate: workScheduleItem.startDate,
        endDate: workScheduleItem.endDate,
        workGroupStartDateTime: workScheduleItem.workGroup?.validFrom,
        workGroupEndDateTime: workScheduleItem.workGroup?.validFrom,
        comment: workScheduleItem.comment,
        regionId: workScheduleItem.regionId,
        ...calculateWorkScheduleItemHours(workScheduleItem),
        isReserveItem: isReserveItem(
            workScheduleItem.items && workScheduleItem.items.length > 0
                ? workScheduleItem.items
                : workScheduleItem.workGroup?.items ?? []
        )
    }
};

export const selectSortedActiveDriverIds = createSelector(
    selectActiveDriverIdsOnMonth,
    selectDriverSortingPositions,
    (activeIds, driverSortingPositions) => {
        return [...activeIds].sort((a, b) => {
            const positionA = driverSortingPositions
                .find(item => item.driverId === parseInt(a.toString()));
            const positionB = driverSortingPositions
                .find(item => item.driverId === parseInt(b.toString()));
            // If neither position is set they are equal
            if (!positionA && !positionB) {
                return 0;
            }
            // If positionA is set, it should be higher, if positionB is set it should be higher
            if (!positionA || !positionB) {
                return positionA ? -1 : 1;
            }

            // Always send positionA=0 to the end
            if (positionA.position === 0 && positionB.position !== 0) {
                return 1;
            }
            // Always send positionB=0 to the end
            if (positionA.position !== 0 && positionB.position === 0) {
                return -1;
            }

            // Otherwise compare positions
            return positionA.position - positionB.position;
        });
    },
);

export const selectAllActiveDrivers = createSelector(
    selectAllDrivers,
    selectMonth,
    (drivers, month) => drivers.filter(driver =>
        driver.active &&
        driver.contracts.some(contract => contractIsActiveOnMonth(contract, month))
    )
);

export const selectExtraDriverIds = createSelector(
    (state: RootState) => state.workSchedule.extraDriverIds,
    selectAllWorkScheduleItems,
    selectAllDriverContracts,
    selectMonth,
    (extraDriverIds, items, contracts, month): number[] => {
        const contractIdsInScheduledItems = items
            .filter(item => item.resourceId)
            .map(item => item.resourceId);
        const driverIdsByContract = contracts
            .filter(contract => contractIdsInScheduledItems.includes(contract.id) && contractIsActiveOnMonth(contract, month))
            .map(contract => contract.driverId);

        return [...driverIdsByContract, ...extraDriverIds]
            .map(id => Number(id))
            .filter((id, index, array) => array.indexOf(id) === index);
    },
);

const selectDriversForExcelFile = (state: RootState): Row[] => {
    const sortedIds = selectSortedActiveDriverIds(state);
    const extraIds = selectExtraDriverIds(state);
    const month = selectMonth(state);
    const selectedRegion = selectSelectedRegion(state);
    const currentMonthDays = selectCurrentMonthDays(state);
    const calendarEntries = selectAllCalendarEntries(state);

    const extraIdsNotInSorted = extraIds.filter(id => !sortedIds.includes(id));

    return [
        [
            {
                value: selectedRegion?.name,
                span: 2,
                align: 'center',
            },
            null,
            ...currentMonthDays.map((day): Cell => ({
                value: Weekday.get(day.day()),
                align: 'center',
                color: isOnWeekend(day) ? '#FF0000' : undefined,
            }))
        ],
        [
            {
                value: dayjs(month).format('MMMM YYYY').toUpperCase(),
                span: 2,
                align: 'center',
            },
            null,
            ...currentMonthDays.map((day): Cell => ({
                value: day.format('DD'),
                align: 'center',
                color: isOnPublicHoliday(day, calendarEntries) ? '#FF0000' : undefined,
            }))
        ],
        ...[...sortedIds, ...extraIdsNotInSorted].flatMap((id): Row[] => {
            const driver = selectDriverById(state, id);
            const driverContracts = driver?.contracts
                .filter(contract => contractIsActiveOnMonth(contract, month)) ?? [];

            return driverContracts.map((contract): Cell[] => {
                const stats = selectContractStats(state, Number(contract.id));

                return [
                    {
                        value: driver ? formatName(driver, 'lastNameFirst') : '',
                    },
                    {
                        value: stats.nominalHours.toFixed(1),
                        align: 'center',
                    },
                    ...currentMonthDays.map((day): Cell => {
                        const activity = selectActivityByDayAndId(Number(contract.id), day)(state);
                        const request = selectRequestByDayAndId(Number(contract.id), day)(state);

                        return {
                            value: getCellLabel(activity ?? request, selectedRegion),
                            align: 'center',
                            fontSize: 15,
                        }
                    }),
                ]
            })
        }),
    ];
};

const selectBusesForExcel = (state: RootState): Row[] => {
    const busIds = selectActiveBusIds(state);
    const month = selectMonth(state);
    const selectedRegion = selectSelectedRegion(state);
    const currentMonthDays = selectCurrentMonthDays(state);
    const calendarEntries = selectAllCalendarEntries(state);

    return [
        [
            {
                value: selectedRegion?.name,
                align: 'center',
            },
            ...currentMonthDays.map((day): Cell => ({
                value: Weekday.get(day.day()),
                align: 'center',
                color: isOnWeekend(day) ? '#FF0000' : undefined,
            }))
        ],
        [
            {
                value: dayjs(month).format('MMMM YYYY').toUpperCase(),
                align: 'center',
            },
            ...currentMonthDays.map((day): Cell => ({
                value: day.format('DD'),
                align: 'center',
                color: isOnPublicHoliday(day, calendarEntries) ? '#FF0000' : undefined,
            }))
        ],
        ...busIds.map((id): Row => {
            const bus = selectBusById(state, id);

            return [
                {
                    value: bus?.licencePlateNumber,
                },
                ...currentMonthDays.map((day): Cell => {
                    const activity = selectActivityByDayAndId(Number(bus?.id), day)(state);
                    const request = selectRequestByDayAndId(Number(bus?.id), day)(state);

                    return {
                        value: getCellLabel(activity ?? request, selectedRegion),
                        align: 'center',
                        fontSize: 15,
                    }
                }),
            ];
        }),
    ];
};

export const selectRowsForExcel = (state: RootState): Row[] => {
    const resourceType = selectToggledResourceType(state);
    return resourceType === ResourceType.DRIVER ? selectDriversForExcelFile(state) : selectBusesForExcel(state);
};
