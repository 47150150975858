import {RefObject, useCallback, useEffect, useRef} from "react";
import {TypedUseSelectorHook, useDispatch, useSelector, useStore} from "react-redux";
import type {AppDispatch, AppStore, RootState} from "../store";
import dayjs, {Dayjs} from "dayjs";
import {setSelectedDay} from "../store/workScheduleSlice";
import {selectBusOptions, selectDriverOptions} from "../scenes/authenticated/workSheets/store/selectors";
import {ResourceType} from "../API/workGroup/types";
import {isActiveOnDay} from "../scenes/authenticated/workSchedule/utils";
import {selectAllDefects, selectWorkScheduleItemsOnDay, WorkSheetsSortType} from "../store/workScheduleItemSlice";
import {DefectSimple} from "../API/defects/types";
import {Region} from "../API/region/types";
import {useLocalStorage} from "usehooks-ts";
import {useOutletContext} from "react-router-dom";
import {SearchContextType} from "../layouts/SearchLayoutWrapper";


export const useCustomRef = (): [RefObject<HTMLDivElement>, (node: HTMLDivElement) => void] => {
    const ref = useRef<HTMLDivElement | null>(null);
    const setRef = useCallback((node: HTMLDivElement | null) => {
        ref.current = node;
    }, []);

    return [ref, setRef];
};

export const useWorkScheduleSelectedDay = () => {
    const selectedDay = useAppSelector(state => state.workSchedule.view.selectedDay);
    const dispatch = useAppDispatch();

    const handleSelectDay = useCallback((day: Dayjs) => () => {
        const dayString = day.format('YYYY-MM-DD');
        if (dayString === selectedDay) {
            dispatch(setSelectedDay(undefined));
        } else {
            dispatch(setSelectedDay(dayString))
        }
    }, [selectedDay]);

    return { selectedDay, handleSelectDay };
};

export interface DriverOption {
    id: number;
    name: string;
}

export interface BusOption extends DriverOption {
    defects: DefectSimple[];
}

export const useResourceOptions = () => {
    const driverOptions = useAppSelector(selectDriverOptions);
    const busOptions = useAppSelector(selectBusOptions);
    const date = useAppSelector(state => state.view.date);
    const workScheduleItems = useAppSelector(selectWorkScheduleItemsOnDay);
    const defects = useAppSelector(selectAllDefects);
    const day = dayjs(date);
    const usedContractIds = workScheduleItems
        .filter(item => item && item.resourceType === ResourceType.DRIVER && item.resourceId)
        .map(item => item ? item.resourceId : null);
    const usedBusIds = workScheduleItems
        .filter(item => item && item.resourceType === ResourceType.VEHICLE && item.resourceId)
        .map(item => item ? item.resourceId : null);

    const availableDrivers: DriverOption[] = driverOptions.filter(driver => {
        if (driver === undefined || !driver.active) {
            return false;
        }

        return isActiveOnDay({
            contractStartDate: dayjs(driver.contractStartDate),
            contractEndDate: driver.contractEndDate ? dayjs(driver.contractEndDate) : null,
            nominalWeeklyWorkingHours: driver.nominalWeeklyWorkingHours,
        }, day) &&  !usedContractIds.includes(driver.id);
    }).map(driver => ({
        id: driver.id,
        name: driver.name,
    })).sort((a, b) => a.name.localeCompare(b.name));

    const availableBuses: BusOption[] = busOptions.filter(bus => {
        if (bus === undefined || !bus.active) {
            return false;
        }

        return !usedBusIds.includes(bus.id);
    }).map(bus => ({
        id: bus.id,
        name: bus.name,
        defects: defects.filter(defect => defect.busId === bus.id),
    })).sort((a, b) => a.name.localeCompare(b.name));

    return {availableDrivers, availableBuses};
};

export const useSearchRegions = () => useLocalStorage<Region[]>('searchRegions', []);
export const useWorkSheetsSortType = () => useLocalStorage<WorkSheetsSortType>('workSheetsSortType', 'code');
export const useFindAndGoToPageOfItem = () => useOutletContext<SearchContextType>()?.findAndGoToPageOfItem;
export const useWorkGroupsPlanningView = (value?: boolean) => {
    const [workGroupsPlanningView, setWorkGroupsPlanningView] = useLocalStorage<boolean>('workGroupsPlanningView', true);

    useEffect(() => {
        if (value !== undefined) {
            setWorkGroupsPlanningView(value)
        }
    }, [value]);

    return workGroupsPlanningView;
};

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;
